import React, { useEffect, createRef } from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import './PageHeader.css'




const PageHeader = ({
  fronttitle,
  title,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => (
    <div class="PageHeader">
        <div class="container reverse">
        <div class="PageHeader--Left">
        <h1 className="PageHeader--FrontTitle">{fronttitle}</h1>
        <p className="PageHeader--Subtitle">{subtitle}</p>
        <a className="button" href="#kontakt">Kontakt meg</a>
        </div>
        <div class="PageHeader--Right">
        <Image src={backgroundImage} alt="Løwe Konditori" className={'container round-corner'}/>
        </div>
      </div>


    </div>
)




PageHeader.propTypes = {
  fronttitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
