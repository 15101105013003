import React from 'react'

import PostCard from '../components/PostCard'
import './PostSection.css'
import Image from '../components/Image'

class FeaturedSection extends React.Component {
  static defaultProps = {
    posts: [],
    title: '',
    limit: 3,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 3
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () =>
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    const { posts, title, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      featuredPosts = posts.slice(0,3)

    return (
      <section className="PostSection">
        {title && <h2 className="PostSection--Title">{title}</h2>}
        {!!featuredPosts.length && (
          <div className="PostSection--Grid-3 animated animatedFadeInUp fadeInUp">
            {featuredPosts.map((post, featured) => (
              <PostCard key={post.title + featured} {...post} />
            ))}
          </div>
        )}

      </section>
    )

  }
}

export default FeaturedSection
