import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Tabs.css';
import Image from './Image'


export default ({
  firstName,
  firstFirstImage,
  firstSecondImage,
  firstThirdImage,
  secondName,
  secondFirstImage,
  secondSecondImage,
  secondThirdImage,
  thirdName,
  thirdFirstImage,
  thirdSecondImage,
  thirdThirdImage,
  fourthName,
  fourthFirstImage,
  fourthSecondImage,
  fourthThirdImage
}) => (
  <Tabs>
    <TabList>
      <Tab>{firstName}</Tab>
      <Tab>{secondName}</Tab>
      <Tab>{thirdName}</Tab>
      <Tab>{fourthName}</Tab>
    </TabList>
    <TabPanel>
        <section class="section row">
            <div class="container taLeft">
                <div class="column3">
                    <Image src={firstFirstImage} alt="Kake" className={'container round-corner'}/>
                </div>
                <div class="column3">
                    <Image src={firstSecondImage} alt="Kake" className={'container round-corner'}/>
                </div>
                <div class="column3">
                    <Image src={firstThirdImage} alt="Kake" className={'container round-corner'}/>
                </div>
            </div>
        </section>
    </TabPanel>
    <TabPanel>
        <section class="section row">
            <div class="container taLeft">
                <div class="column3">
                    <Image src={secondFirstImage} alt="Makroner" className={'container round-corner'}/>
                </div>
                <div class="column3">
                    <Image src={secondSecondImage} alt="Makroner" className={'container round-corner'}/>
                </div>
                <div class="column3">
                    <Image src={secondThirdImage} alt="Makroner" className={'container round-corner'}/>
                </div>
            </div>
        </section>
    </TabPanel>
    <TabPanel>
        <section class="section row">
            <div class="container taLeft">
                <div class="column3">
                    <Image src={thirdFirstImage} alt="Karameller" className={'container round-corner'}/>
                </div>
                <div class="column3">
                    <Image src={thirdSecondImage} alt="Karameller" className={'container round-corner'}/>
                </div>
                <div class="column3">
                    <Image src={thirdThirdImage} alt="Karameller" className={'container round-corner'}/>
                </div>
            </div>
        </section>
    </TabPanel>
    <TabPanel>
        <section class="section row">
            <div class="container taLeft">
                <div class="column3">
                    <Image src={fourthFirstImage} alt="Gjærbakst" className={'container round-corner'}/>
                </div>
            </div>
        </section>
    </TabPanel>
  </Tabs>
)
