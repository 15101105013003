import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import '../components/Footer.css'
import '../components/PostCard.css'
import '../components/Content.css'
import Image from '../components/Image'
import PostSection from '../components/PostSectionFeatured'
import Tabs from '../components/Tabs-bakverk'
import FormSimpleAjax from '../components/FormSimpleAjax'

import PostCard from '../components/PostCard'

import CookieConsent from "react-cookie-consent";


const HomePage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
    <PageHeader
      large
      fronttitle={data.strapiHomepage.hero.title}
      subtitle={data.strapiHomepage.hero.subtitle}
      backgroundImage={data.strapiHomepage.hero.featuredImage.publicURL}
    />
      <section className="section row">
        <div className="container">
        <h2>{data.strapiHomepage.utvalgteBakverk.headline}</h2>
          <Tabs
            firstName={data.strapiHomepage.utvalgteBakverk.firstBakverk.name}
            firstFirstImage={data.strapiHomepage.utvalgteBakverk.firstBakverk.firstImage.publicURL}
            firstSecondImage={data.strapiHomepage.utvalgteBakverk.firstBakverk.secondImage.publicURL}
            firstThirdImage={data.strapiHomepage.utvalgteBakverk.firstBakverk.thirdImage.publicURL}
            secondName={data.strapiHomepage.utvalgteBakverk.secondBakverk.name}
            secondFirstImage={data.strapiHomepage.utvalgteBakverk.secondBakverk.firstImage.publicURL}
            secondSecondImage={data.strapiHomepage.utvalgteBakverk.secondBakverk.secondImage.publicURL}
            secondThirdImage={data.strapiHomepage.utvalgteBakverk.secondBakverk.thirdImage.publicURL}
            thirdName={data.strapiHomepage.utvalgteBakverk.thirdBakverk.name}
            thirdFirstImage={data.strapiHomepage.utvalgteBakverk.thirdBakverk.firstImage.publicURL}
            thirdSecondImage={data.strapiHomepage.utvalgteBakverk.thirdBakverk.secondImage.publicURL}
            thirdThirdImage={data.strapiHomepage.utvalgteBakverk.thirdBakverk.thirdImage.publicURL}
            fourthName={data.strapiHomepage.utvalgteBakverk.fourthBakverk.name}
            fourthFirstImage={data.strapiHomepage.utvalgteBakverk.fourthBakverk.firstImage.publicURL}


          />
        </div>
      </section>

      <section className="section row space-100b">
        <div className="container">
          <div className="taCenter">
              <h2 class="space-headline">{data.strapiHomepage.features.headline}</h2>
          </div>
          <div className="column4 icon">
              <Image src={data.strapiHomepage.features.firstFeature.symbol.publicURL} alt={data.strapiHomepage.features.firstFeature.name} className={'container icon'} />
              <h3>{data.strapiHomepage.features.firstFeature.name}</h3>
              <p>{data.strapiHomepage.features.firstFeature.description}</p>
          </div>
          <div className="column4 icon">
              <Image src={data.strapiHomepage.features.secondFeature.symbol.publicURL} alt={data.strapiHomepage.features.secondFeature.name} className={'container icon'} />
              <h3>{data.strapiHomepage.features.secondFeature.name}</h3>
              <p>{data.strapiHomepage.features.secondFeature.description}</p>
          </div>
          <div className="column4 icon">
              <Image src={data.strapiHomepage.features.thirdFeature.symbol.publicURL} alt={data.strapiHomepage.features.thirdFeature.name} className={'container icon'} />
              <h3>{data.strapiHomepage.features.thirdFeature.name}</h3>
              <p>{data.strapiHomepage.features.thirdFeature.description}</p>
          </div>
          <div className="column4 icon">
              <Image src={data.strapiHomepage.features.fourthFeature.symbol.publicURL} alt={data.strapiHomepage.features.fourthFeature.name} className={'container icon'} />
              <h3>{data.strapiHomepage.features.fourthFeature.name}</h3>
              <p>{data.strapiHomepage.features.fourthFeature.description}</p>
          </div>
        </div>
      </section>

      <section className="section row" id="om">
        <div className="container reverse">
          <div className="about-left">
              <h2>{data.strapiHomepage.about.headline}</h2>
              <p>{data.strapiHomepage.about.bio}</p>
              <p class="name-margin">{data.strapiHomepage.about.role}</p>
              <h3>{data.strapiHomepage.about.name}</h3>
          </div>
          <div className="about-right">
              <Image src={data.strapiHomepage.about.image.publicURL} alt={data.strapiHomepage.about.headline} className={'container round-corner'} />
          </div>
        </div>
      </section>

      <section className="section row" id="anbefalinger">
        <div className="container skinnier taCenter">
          <h2 class="space-headline">{data.strapiHomepage.testimonial.headline}</h2>
          <p>{data.strapiHomepage.testimonial.quote}</p>
          <h3>{data.strapiHomepage.testimonial.author}</h3>
        </div>
      </section>

      <section className="section row space-100b" id="kontakt">
        <div className="container skinnier taCenter">
          <h2 class="space-headline">{data.strapiHomepage.contact.headline}</h2>
          <p>{data.strapiHomepage.contact.description}</p>
          <div className="column">
              <h3>E-post:</h3>
          </div>
          <div className="column">
              <a href={`mailto:${encodeURI(data.strapiHomepage.contact.email)}`}><p>{data.strapiHomepage.contact.email}</p></a>
          </div>
          <div className="column">
              <h3>Facebook:</h3>
          </div>
          <div className="column">
              <a href={`https://www.facebook.com/${encodeURI(data.strapiHomepage.contact.facebook)}`}><p>@{data.strapiHomepage.contact.facebook}</p></a>
          </div>
          <div className="column">
              <h3>Instagram:</h3>
          </div>
          <div className="column">
              <a href={`https://www.instagram.com/${encodeURI(data.strapiHomepage.contact.instagram)}`}><p>@{data.strapiHomepage.contact.instagram}</p></a>
          </div>
        </div>
      </section>



    </Layout>


  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
        subtitle
        featuredImage {
          publicURL
        }
      }
      utvalgteBakverk {
          headline
          firstBakverk {
              name
              firstImage {
                  publicURL
              }
              secondImage {
                  publicURL
              }
              thirdImage {
                  publicURL
              }
          }
          secondBakverk {
              name
              firstImage {
                  publicURL
              }
              secondImage {
                  publicURL
              }
              thirdImage {
                  publicURL
              }
          }
          thirdBakverk {
              name
              firstImage {
                  publicURL
              }
              secondImage {
                  publicURL
              }
              thirdImage {
                  publicURL
              }
          }
          fourthBakverk {
              name
              firstImage {
                  publicURL
              }
          }
      }
      features {
          headline
          firstFeature {
              name
              description
              symbol {
                  publicURL
              }
          }
          secondFeature {
              name
              description
              symbol {
                  publicURL
              }
          }
          thirdFeature {
              name
              description
              symbol {
                  publicURL
              }
          }
          fourthFeature {
              name
              description
              symbol {
                  publicURL
              }
          }
      }
      about {
          headline
          bio
          role
          name
          image {
              publicURL
          }
      }
      testimonial {
          headline
          quote
          author
      }
      contact {
          headline
          description
          email
          facebook
          instagram
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default HomePage;
