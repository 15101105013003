import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import './PostCard.css'

const PostCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  date,
  portrait,
  reading,
  author,
  categories = [],
  className = '',
  ...props
}) => (
    <Link to={slug} className={`PostCard ${className}`}>
      {featuredImage && (
        <div className="PostCard--Image relative">
          <Image background src={featuredImage} alt={title} className={'round-corner'}/>

        </div>
      )}
    <div className="PostCard--Content">
      {title && <h3 className="PostCard--Title">{title}</h3>}
      {excerpt && <p className="PostCard--Excerpt">{excerpt}</p>}

    </div>
  </Link>
)

export default PostCard
